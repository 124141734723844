import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import CustomerCase from '../components/CustomerCase';
import VerticalBox from '../components/VerticalBox';
import IconText from '../components/IconText';
import Samsclub from '../images/samsclub.svg';

function CustomIconText({ title, icon, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} icon={icon} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string,
};

export default () => (
  <Layout current="solutions" subCurrent="modernretail">
    <CommonPageBanner
      title="现代零售行业解决方案"
      description="帮助大型商超、连锁便利店构建以客户为中心的私域流量运营和智慧零售营销体系，有效提升续卡率、会员消费占比和流量运营效率，实现现代商超零售业的数字化营销转型。"
      imgName="modern-retail"
    />
    <TitledVerticalBoxes title="全渠道客户运营体系">
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={35}
          title="线上+线下"
          description="打通线上和线下渠道，对全渠道客户及会员进行统一管理和运营"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={36}
          title="私域+公域"
          description="建立跨越公域和私域流量的全渠道运营机制，实现公私域流量互导"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={2}
          title="精准用户洞察"
          description="建立CDP客户数据平台，获得消费者360°画像，精准识别客户"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={10}
          title="精准预测推荐"
          description="一站式AI智能推荐引擎，对消费者商品偏好进行精准预测和推荐"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={37}
          title="新业态运营"
          description="对O2O、社区团购、网店、直播营销等全新运营模式进行有效管理"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={31}
          title="自动化运营"
          description="基于营销自动化的消费者旅程设计，实现Always-on持续营销运营"
        />
      </Col>
    </TitledVerticalBoxes>
    <TitledVerticalBoxes title="一体化会员中台系统" shadowed>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={1} description="新会员促进首购" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={20} description="会员个性化场景营销" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={21} description="会员生命周期自动化维护" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={22} description="全渠道会员增长" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={9} description="会员画像体系" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={23} description="会员标签体系" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={24} description="会员积分管理和运营" />
      </Col>
      <Col className="icon-text-col" lg={6} md={12} xs={12}>
        <VerticalBox iconIndex={6} description="会员权益体系" />
      </Col>
    </TitledVerticalBoxes>
    <CustomerCase
      title="现代零售客户案例"
      icon={Samsclub}
      paddingTop={0}
      description="山姆会员商店是世界500强企业沃尔玛旗下的高端会员制商店，其名取自零售界传奇人物——沃尔玛创始人山姆·沃尔顿先生。自1983年4月首家山姆会员商店在美国俄克拉荷马州的米德韦斯特城开业起，山姆已有超过30年的历史。90年代初，山姆开始进入国际市场，发展至今已成为全球最大的会员制商店之一。山姆在全球已拥有800多家门店，为5000多万个人与商业会员提供优质的购物体验；中国第一家山姆会员商店于1996年8月12日落户深圳，至今在中国开设了23家商店，拥有200多万会员。"
      challenges={[
        {
          id: 'icon-1',
          iconIndex: 35,
          className: 'thin-square high',
          description: '线上线下各渠道数据割裂，会员和微信粉丝等数据形成一个个数据孤岛，无法统一识别身份拉动APP注册。',
        },
        {
          id: 'icon-2',
          iconIndex: 7,
          className: 'thin-square high',
          description: '难以对客户人群及商品标签进行智能化筛选、管理和打标。',
        },
        {
          id: 'icon-3',
          iconIndex: 31,
          className: 'thin-square high',
          description: '传统的营销效率低，缺少自动营销工具，难以实现一系列不同客户阶段、会员关怀关键时刻的培育触达场景。',
        },
        {
          id: 'icon-4',
          iconIndex: 18,
          className: 'thin-square high',
          description: '多渠道内容互动追踪成为难点，无法追踪会员在小程序、微信公众号、App等渠道的完整行为路径、内容互动偏好情况。',
        },
      ]}
      solutions={[
        '【千人千面】营销策略，精细化客户画像，实现精准人群触达为不同会员推送个性化内容和商品信息。',
        '全渠道参与会员生命周期管理，自动流程场景工具提升营销效率，丰富营销活动，加强与消费者的互动，增加黏性。',
        '通过设置用户活跃度模型，预判用户生命周期关键节点，设置【老会员常态化促活】、【低活用户防流失护城河】等用户旅程自动化维护流程，提升CLV。',
        '通过构建智能用户标签体系，完成用户画像的深度洞察，实现基于用户画像的分群营销以及续卡概率的会员运营决策树营销，提升转化率。',
      ]}
      effects={[
        {
          id: 'icon-1',
          iconId: 'iconeffect-9',
          content: '续卡率提升：通过自动化续卡提醒流程，在未增加任何营销投入的前提下，会员月度续卡率平均提升3%~5%，获得ROI净增长。',
        },
        {
          id: 'icon-2',
          iconId: 'iconeffect-10',
          content: '复购率提升：通过自动化促活流程+智能推荐，山姆会员全渠道复购率显著提升。',
        },
        {
          id: 'icon-3',
          iconId: 'iconeffect-8',
          content: '活跃用户占比提升：通过自动化促活低活跃用户及自动化唤醒沉睡用户流程，提升山姆活跃会员占比。',
        },
        {
          id: 'icon-4',
          iconId: 'iconeffect-11',
          content: '运营策略自动执行：依用户旅程及关键触点(MOT)设计用户培育流程，以持续性流程（always on）自动且不间断自动化执行促活、召回，唤醒等用户生命周期场景流程。',
        },
        {
          id: 'icon-5',
          iconId: 'iconeffect-1',
          content: '运营数据即时复盘：全触点行为数据时时回流DM Hub，运营人员可自行通过多种组合条件即时生成活动漏斗分析、用户行为偏好分析、订单分析等数据看板，即时复盘，立即调整策略，提高转化。',
        },
      ]}
    />
  </Layout>
);
